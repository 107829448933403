
import Vue from 'vue'

const locales = {keys(){return []}}
global['____A50C03B____'] = true;
delete global['____A50C03B____'];
global.__uniConfig = {"tabBar":{"color":"#666","selectedColor":"#FF2C3C","borderStyle":"black","list":[{"pagePath":"pages/index/index","iconPath":"/static/images/tab_home.png","selectedIconPath":"/static/images/tab_home_s.png","text":"首页","redDot":false,"badge":""},{"pagePath":"pages/sort/sort","iconPath":"/static/images/tab_sort.png","selectedIconPath":"/static/images/tab_sort_s.png","text":"分类","redDot":false,"badge":""},{"pagePath":"pages/shop_cart/shop_cart","iconPath":"/static/images/tab_cart.png","selectedIconPath":"/static/images/tab_cart_s.png","text":"购物车","redDot":false,"badge":""},{"pagePath":"pages/user/user","iconPath":"/static/images/tab_user.png","selectedIconPath":"/static/images/tab_user_s.png","text":"我的","redDot":false,"badge":""}],"backgroundColor":""},"globalStyle":{"navigationBarTextStyle":"black","navigationBarTitleText":"LikeShop","navigationBarBackgroundColor":"#FFFFFF","backgroundColor":"#F8F8F8","navigationStyle":"custom"},"easycom":{"autoscan":true,"custom":{"^u-(.*)":"@/components/uview-ui/components/u-$1/u-$1.vue","^unicloud-db$":"@dcloudio/uni-cli-shared/components/unicloud-db.vue","^uniad$":"@dcloudio/uni-cli-shared/components/uniad.vue","^ad-rewarded-video$":"@dcloudio/uni-cli-shared/components/ad-rewarded-video.vue","^ad-fullscreen-video$":"@dcloudio/uni-cli-shared/components/ad-fullscreen-video.vue","^ad-interstitial$":"@dcloudio/uni-cli-shared/components/ad-interstitial.vue","^ad-interactive$":"@dcloudio/uni-cli-shared/components/ad-interactive.vue","^page-meta$":"@dcloudio/uni-cli-shared/components/page-meta.vue","^navigation-bar$":"@dcloudio/uni-cli-shared/components/navigation-bar.vue","^uni-match-media$":"@dcloudio/uni-cli-shared/components/uni-match-media.vue"}}};
global.__uniConfig.compilerVersion = '4.15';
global.__uniConfig.darkmode = false;
global.__uniConfig.themeConfig = {};
global.__uniConfig.uniPlatform = 'h5';
global.__uniConfig.appId = '__UNI__A50C03B';
global.__uniConfig.appName = '报单中心';
global.__uniConfig.appVersion = '2.4';
global.__uniConfig.appVersionCode = '100';
global.__uniConfig.router = {"mode":"history","base":"/mobile/"};
global.__uniConfig.publicPath = "/mobile/";
global.__uniConfig['async'] = {"loading":"AsyncLoading","error":"AsyncError","delay":200,"timeout":60000};
global.__uniConfig.debug = false;
global.__uniConfig.networkTimeout = {"request":60000,"connectSocket":60000,"uploadFile":60000,"downloadFile":60000};
global.__uniConfig.sdkConfigs = {};
global.__uniConfig.qqMapKey = undefined;
global.__uniConfig.googleMapKey = undefined;
global.__uniConfig.aMapKey = undefined;
global.__uniConfig.aMapSecurityJsCode = undefined;
global.__uniConfig.aMapServiceHost = undefined;
global.__uniConfig.locale = "";
global.__uniConfig.fallbackLocale = undefined;
global.__uniConfig.locales = locales.keys().reduce((res,key)=>{const locale=key.replace(/\.\/(uni-app.)?(.*).json/,'$2');const messages = locales(key);Object.assign(res[locale]||(res[locale]={}),messages.common||messages);return res},{});
global.__uniConfig.nvue = {"flex-direction":"column"}
global.__uniConfig.__webpack_chunk_load__ = __webpack_chunk_load__
Vue.component('pages-index-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/index/index"+'.vue')), 'pages-index-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-address_edit-address_edit', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/address_edit/address_edit"+'.vue')), 'pages-address_edit-address_edit'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user_address-user_address', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/user_address/user_address"+'.vue')), 'pages-user_address-user_address'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-news_list-news_list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/news_list/news_list"+'.vue')), 'pages-news_list-news_list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-news_details-news_details', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/news_details/news_details"+'.vue')), 'pages-news_details-news_details'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-sort-sort', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/sort/sort"+'.vue')), 'pages-sort-sort'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-shop_cart-shop_cart', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/shop_cart/shop_cart"+'.vue')), 'pages-shop_cart-shop_cart'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-user', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/user/user"+'.vue')), 'pages-user-user'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-goods_search-goods_search', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/goods_search/goods_search"+'.vue')), 'pages-goods_search-goods_search'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-goods_details-goods_details', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/goods_details/goods_details"+'.vue')), 'pages-goods_details-goods_details'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-confirm_order-confirm_order', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/confirm_order/confirm_order"+'.vue')), 'pages-confirm_order-confirm_order'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user_collection-user_collection', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/user_collection/user_collection"+'.vue')), 'pages-user_collection-user_collection'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user_coupon-user_coupon', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/user_coupon/user_coupon"+'.vue')), 'pages-user_coupon-user_coupon'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user_getcoupon-user_getcoupon', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/user_getcoupon/user_getcoupon"+'.vue')), 'pages-user_getcoupon-user_getcoupon'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user_order-user_order', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/user_order/user_order"+'.vue')), 'pages-user_order-user_order'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order_details-order_details', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/order_details/order_details"+'.vue')), 'pages-order_details-order_details'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-all_comments-all_comments', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/all_comments/all_comments"+'.vue')), 'pages-all_comments-all_comments'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user_vip-user_vip', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/user_vip/user_vip"+'.vue')), 'pages-user_vip-user_vip'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-pay_result-pay_result', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/pay_result/pay_result"+'.vue')), 'pages-pay_result-pay_result'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-webview-webview', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/webview/webview"+'.vue')), 'pages-webview-webview'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-goods_seckill-goods_seckill', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/goods_seckill/goods_seckill"+'.vue')), 'pages-bundle-goods_seckill-goods_seckill'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-goods_logistics-goods_logistics', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/goods_logistics/goods_logistics"+'.vue')), 'pages-bundle-goods_logistics-goods_logistics'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-goods_comment_list-goods_comment_list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/goods_comment_list/goods_comment_list"+'.vue')), 'pages-bundle-goods_comment_list-goods_comment_list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-goods_reviews-goods_reviews', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/goods_reviews/goods_reviews"+'.vue')), 'pages-bundle-goods_reviews-goods_reviews'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-activity_detail-activity_detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/activity_detail/activity_detail"+'.vue')), 'pages-bundle-activity_detail-activity_detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-goods_combination-goods_combination', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/goods_combination/goods_combination"+'.vue')), 'pages-bundle-goods_combination-goods_combination'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_group-user_group', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/user_group/user_group"+'.vue')), 'pages-bundle-user_group-user_group'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-hot_list-hot_list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/hot_list/hot_list"+'.vue')), 'pages-bundle-hot_list-hot_list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_sign-user_sign', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/user_sign/user_sign"+'.vue')), 'pages-bundle-user_sign-user_sign'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-sign_detail-sign_detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/sign_detail/sign_detail"+'.vue')), 'pages-bundle-sign_detail-sign_detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-sign_rule-sign_rule', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/sign_rule/sign_rule"+'.vue')), 'pages-bundle-sign_rule-sign_rule'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_spread-user_spread', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/user_spread/user_spread"+'.vue')), 'pages-bundle-user_spread-user_spread'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-invite_fans-invite_fans', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/invite_fans/invite_fans"+'.vue')), 'pages-bundle-invite_fans-invite_fans'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_spread_month_bill-user_spread_month_bill', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/user_spread_month_bill/user_spread_month_bill"+'.vue')), 'pages-bundle-user_spread_month_bill-user_spread_month_bill'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_spread_month_bill_detail-user_spread_month_bill_detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/user_spread_month_bill_detail/user_spread_month_bill_detail"+'.vue')), 'pages-bundle-user_spread_month_bill_detail-user_spread_month_bill_detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_spread_order-user_spread_order', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/user_spread_order/user_spread_order"+'.vue')), 'pages-bundle-user_spread_order-user_spread_order'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_fans-user_fans', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/user_fans/user_fans"+'.vue')), 'pages-bundle-user_fans-user_fans'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_withdraw-user_withdraw', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/user_withdraw/user_withdraw"+'.vue')), 'pages-bundle-user_withdraw-user_withdraw'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_withdraw_code-user_withdraw_code', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/user_withdraw_code/user_withdraw_code"+'.vue')), 'pages-bundle-user_withdraw_code-user_withdraw_code'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_wallet-user_wallet', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/user_wallet/user_wallet"+'.vue')), 'pages-bundle-user_wallet-user_wallet'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_integral-user_integral', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/user_integral/user_integral"+'.vue')), 'pages-bundle-user_integral-user_integral'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_bill-user_bill', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/user_bill/user_bill"+'.vue')), 'pages-bundle-user_bill-user_bill'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_payment-user_payment', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/user_payment/user_payment"+'.vue')), 'pages-bundle-user_payment-user_payment'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-widthdraw_result-widthdraw_result', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/widthdraw_result/widthdraw_result"+'.vue')), 'pages-bundle-widthdraw_result-widthdraw_result'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-server_explan-server_explan', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/server_explan/server_explan"+'.vue')), 'pages-bundle-server_explan-server_explan'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-message_center-message_center', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/message_center/message_center"+'.vue')), 'pages-bundle-message_center-message_center'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-notice-notice', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/notice/notice"+'.vue')), 'pages-bundle-notice-notice'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-user_profile-user_profile', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/user_profile/user_profile"+'.vue')), 'pages-bundle-user_profile-user_profile'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-post_sale-post_sale', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/post_sale/post_sale"+'.vue')), 'pages-bundle-post_sale-post_sale'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-apply_refund-apply_refund', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/apply_refund/apply_refund"+'.vue')), 'pages-bundle-apply_refund-apply_refund'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-after_sales_detail-after_sales_detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/after_sales_detail/after_sales_detail"+'.vue')), 'pages-bundle-after_sales_detail-after_sales_detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-input_express_info-input_express_info', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/input_express_info/input_express_info"+'.vue')), 'pages-bundle-input_express_info-input_express_info'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-contact_offical-contact_offical', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/contact_offical/contact_offical"+'.vue')), 'pages-bundle-contact_offical-contact_offical'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-luckly_wheel-luckly_wheel', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/luckly_wheel/luckly_wheel"+'.vue')), 'pages-bundle-luckly_wheel-luckly_wheel'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-prize_record-prize_record', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/prize_record/prize_record"+'.vue')), 'pages-bundle-prize_record-prize_record'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-bargain-bargain', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/bargain/bargain"+'.vue')), 'pages-bundle-bargain-bargain'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-bargain_process-bargain_process', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/bargain_process/bargain_process"+'.vue')), 'pages-bundle-bargain_process-bargain_process'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-bargain_code-bargain_code', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/bargain_code/bargain_code"+'.vue')), 'pages-bundle-bargain_code-bargain_code'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-recharge_code-recharge_code', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/recharge_code/recharge_code"+'.vue')), 'pages-bundle-recharge_code-recharge_code'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-balance_transfer-balance_transfer', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/balance_transfer/balance_transfer"+'.vue')), 'pages-bundle-balance_transfer-balance_transfer'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-integral_transfer-integral_transfer', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/integral_transfer/integral_transfer"+'.vue')), 'pages-bundle-integral_transfer-integral_transfer'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-transfer_record-transfer_record', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/transfer_record/transfer_record"+'.vue')), 'pages-bundle-transfer_record-transfer_record'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-integral_transfer_record-integral_transfer_record', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/integral_transfer_record/integral_transfer_record"+'.vue')), 'pages-bundle-integral_transfer_record-integral_transfer_record'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-set_pay_pwd-set_pay_pwd', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/set_pay_pwd/set_pay_pwd"+'.vue')), 'pages-bundle-set_pay_pwd-set_pay_pwd'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-bundle-forget_pay_pwd-forget_pay_pwd', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/home/ubuntu/aymm-uniapp/src/pages/bundle/forget_pay_pwd/forget_pay_pwd"+'.vue')), 'pages-bundle-forget_pay_pwd-forget_pay_pwd'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
global.__uniRoutes=[
{
path: '/',
alias:'/pages/index/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isEntry:true,
isTabBar:true,

          
          tabBarIndex:0
        },__uniConfig.globalStyle,{"navigationStyle":"custom","enablePullDownRefresh":true,"navigationBarTitleText":"首页","backgroundColorTop":"#FFFFFF","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-index-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:1,
  name:'pages-index-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/index',
isQuit:true,
isEntry:true,
isTabBar:true,
tabBarIndex:0,
  windowTop:0
}
},
{
path: '/pages/address_edit/address_edit',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"收货地址"})
      },
      [
        createElement('pages-address_edit-address_edit', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-address_edit-address_edit',
  isNVue:false,maxWidth:0,
  pagePath:'pages/address_edit/address_edit',
  windowTop:0
}
},
{
path: '/pages/user_address/user_address',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"收货地址"})
      },
      [
        createElement('pages-user_address-user_address', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user_address-user_address',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user_address/user_address',
  windowTop:0
}
},
{
path: '/pages/news_list/news_list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"商城资讯"})
      },
      [
        createElement('pages-news_list-news_list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-news_list-news_list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/news_list/news_list',
  windowTop:0
}
},
{
path: '/pages/news_details/news_details',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"资讯详情"})
      },
      [
        createElement('pages-news_details-news_details', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-news_details-news_details',
  isNVue:false,maxWidth:0,
  pagePath:'pages/news_details/news_details',
  windowTop:0
}
},
{
path: '/pages/sort/sort',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isTabBar:true,

          
          tabBarIndex:1
        },__uniConfig.globalStyle,{"navigationBarTitleText":"分类","disableScroll":true})
      },
      [
        createElement('pages-sort-sort', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:2,
  name:'pages-sort-sort',
  isNVue:false,maxWidth:0,
  pagePath:'pages/sort/sort',
isQuit:true,
isTabBar:true,
tabBarIndex:1,
  windowTop:0
}
},
{
path: '/pages/shop_cart/shop_cart',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isTabBar:true,

          
          tabBarIndex:2
        },__uniConfig.globalStyle,{"navigationBarTitleText":"购物车"})
      },
      [
        createElement('pages-shop_cart-shop_cart', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:3,
  name:'pages-shop_cart-shop_cart',
  isNVue:false,maxWidth:0,
  pagePath:'pages/shop_cart/shop_cart',
isQuit:true,
isTabBar:true,
tabBarIndex:2,
  windowTop:0
}
},
{
path: '/pages/user/user',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isTabBar:true,

          
          tabBarIndex:3
        },__uniConfig.globalStyle,{"navigationBarTitleText":"个人中心","navigationStyle":"custom","enablePullDownRefresh":true})
      },
      [
        createElement('pages-user-user', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:4,
  name:'pages-user-user',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/user',
isQuit:true,
isTabBar:true,
tabBarIndex:3,
  windowTop:0
}
},
{
path: '/pages/goods_search/goods_search',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"商品搜索"})
      },
      [
        createElement('pages-goods_search-goods_search', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-goods_search-goods_search',
  isNVue:false,maxWidth:0,
  pagePath:'pages/goods_search/goods_search',
  windowTop:0
}
},
{
path: '/pages/goods_details/goods_details',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"商品详情"})
      },
      [
        createElement('pages-goods_details-goods_details', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-goods_details-goods_details',
  isNVue:false,maxWidth:0,
  pagePath:'pages/goods_details/goods_details',
  windowTop:0
}
},
{
path: '/pages/confirm_order/confirm_order',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"确认订单"})
      },
      [
        createElement('pages-confirm_order-confirm_order', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-confirm_order-confirm_order',
  isNVue:false,maxWidth:0,
  pagePath:'pages/confirm_order/confirm_order',
  windowTop:0
}
},
{
path: '/pages/user_collection/user_collection',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的收藏"})
      },
      [
        createElement('pages-user_collection-user_collection', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user_collection-user_collection',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user_collection/user_collection',
  windowTop:0
}
},
{
path: '/pages/user_coupon/user_coupon',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的优惠券"})
      },
      [
        createElement('pages-user_coupon-user_coupon', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user_coupon-user_coupon',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user_coupon/user_coupon',
  windowTop:0
}
},
{
path: '/pages/user_getcoupon/user_getcoupon',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"领券中心"})
      },
      [
        createElement('pages-user_getcoupon-user_getcoupon', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user_getcoupon-user_getcoupon',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user_getcoupon/user_getcoupon',
  windowTop:0
}
},
{
path: '/pages/user_order/user_order',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的订单","enablePullDownRefresh":true})
      },
      [
        createElement('pages-user_order-user_order', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user_order-user_order',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user_order/user_order',
  windowTop:0
}
},
{
path: '/pages/order_details/order_details',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"订单详情"})
      },
      [
        createElement('pages-order_details-order_details', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order_details-order_details',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order_details/order_details',
  windowTop:0
}
},
{
path: '/pages/all_comments/all_comments',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"全部评价"})
      },
      [
        createElement('pages-all_comments-all_comments', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-all_comments-all_comments',
  isNVue:false,maxWidth:0,
  pagePath:'pages/all_comments/all_comments',
  windowTop:0
}
},
{
path: '/pages/user_vip/user_vip',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"会员中心"})
      },
      [
        createElement('pages-user_vip-user_vip', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user_vip-user_vip',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user_vip/user_vip',
  windowTop:0
}
},
{
path: '/pages/pay_result/pay_result',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"支付详情"})
      },
      [
        createElement('pages-pay_result-pay_result', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-pay_result-pay_result',
  isNVue:false,maxWidth:0,
  pagePath:'pages/pay_result/pay_result',
  windowTop:0
}
},
{
path: '/pages/webview/webview',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":""})
      },
      [
        createElement('pages-webview-webview', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-webview-webview',
  isNVue:false,maxWidth:0,
  pagePath:'pages/webview/webview',
  windowTop:0
}
},
{
path: '/pages/bundle/goods_seckill/goods_seckill',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"商品秒杀"})
      },
      [
        createElement('pages-bundle-goods_seckill-goods_seckill', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-goods_seckill-goods_seckill',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/goods_seckill/goods_seckill',
  windowTop:0
}
},
{
path: '/pages/bundle/goods_logistics/goods_logistics',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"物流详情"})
      },
      [
        createElement('pages-bundle-goods_logistics-goods_logistics', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-goods_logistics-goods_logistics',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/goods_logistics/goods_logistics',
  windowTop:0
}
},
{
path: '/pages/bundle/goods_comment_list/goods_comment_list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"商品评价列表"})
      },
      [
        createElement('pages-bundle-goods_comment_list-goods_comment_list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-goods_comment_list-goods_comment_list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/goods_comment_list/goods_comment_list',
  windowTop:0
}
},
{
path: '/pages/bundle/goods_reviews/goods_reviews',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"商品评价"})
      },
      [
        createElement('pages-bundle-goods_reviews-goods_reviews', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-goods_reviews-goods_reviews',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/goods_reviews/goods_reviews',
  windowTop:0
}
},
{
path: '/pages/bundle/activity_detail/activity_detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{})
      },
      [
        createElement('pages-bundle-activity_detail-activity_detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-activity_detail-activity_detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/activity_detail/activity_detail',
  windowTop:0
}
},
{
path: '/pages/bundle/goods_combination/goods_combination',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"拼团活动","enablePullDownRefresh":true})
      },
      [
        createElement('pages-bundle-goods_combination-goods_combination', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-goods_combination-goods_combination',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/goods_combination/goods_combination',
  windowTop:0
}
},
{
path: '/pages/bundle/user_group/user_group',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的拼团","enablePullDownRefresh":true})
      },
      [
        createElement('pages-bundle-user_group-user_group', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_group-user_group',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_group/user_group',
  windowTop:0
}
},
{
path: '/pages/bundle/hot_list/hot_list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"热销榜单"})
      },
      [
        createElement('pages-bundle-hot_list-hot_list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-hot_list-hot_list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/hot_list/hot_list',
  windowTop:0
}
},
{
path: '/pages/bundle/user_sign/user_sign',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"签到"})
      },
      [
        createElement('pages-bundle-user_sign-user_sign', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_sign-user_sign',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_sign/user_sign',
  windowTop:0
}
},
{
path: '/pages/bundle/sign_detail/sign_detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"积分明细"})
      },
      [
        createElement('pages-bundle-sign_detail-sign_detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-sign_detail-sign_detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/sign_detail/sign_detail',
  windowTop:0
}
},
{
path: '/pages/bundle/sign_rule/sign_rule',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"签到规则"})
      },
      [
        createElement('pages-bundle-sign_rule-sign_rule', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-sign_rule-sign_rule',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/sign_rule/sign_rule',
  windowTop:0
}
},
{
path: '/pages/bundle/user_spread/user_spread',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"商户中心"})
      },
      [
        createElement('pages-bundle-user_spread-user_spread', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_spread-user_spread',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_spread/user_spread',
  windowTop:0
}
},
{
path: '/pages/bundle/invite_fans/invite_fans',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"邀请海报"})
      },
      [
        createElement('pages-bundle-invite_fans-invite_fans', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-invite_fans-invite_fans',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/invite_fans/invite_fans',
  windowTop:0
}
},
{
path: '/pages/bundle/user_spread_month_bill/user_spread_month_bill',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"月度账单"})
      },
      [
        createElement('pages-bundle-user_spread_month_bill-user_spread_month_bill', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_spread_month_bill-user_spread_month_bill',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_spread_month_bill/user_spread_month_bill',
  windowTop:0
}
},
{
path: '/pages/bundle/user_spread_month_bill_detail/user_spread_month_bill_detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"月度账单详情"})
      },
      [
        createElement('pages-bundle-user_spread_month_bill_detail-user_spread_month_bill_detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_spread_month_bill_detail-user_spread_month_bill_detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_spread_month_bill_detail/user_spread_month_bill_detail',
  windowTop:0
}
},
{
path: '/pages/bundle/user_spread_order/user_spread_order',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"分销订单"})
      },
      [
        createElement('pages-bundle-user_spread_order-user_spread_order', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_spread_order-user_spread_order',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_spread_order/user_spread_order',
  windowTop:0
}
},
{
path: '/pages/bundle/user_fans/user_fans',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的粉丝"})
      },
      [
        createElement('pages-bundle-user_fans-user_fans', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_fans-user_fans',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_fans/user_fans',
  windowTop:0
}
},
{
path: '/pages/bundle/user_withdraw/user_withdraw',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"提现"})
      },
      [
        createElement('pages-bundle-user_withdraw-user_withdraw', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_withdraw-user_withdraw',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_withdraw/user_withdraw',
  windowTop:0
}
},
{
path: '/pages/bundle/user_withdraw_code/user_withdraw_code',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"提现记录"})
      },
      [
        createElement('pages-bundle-user_withdraw_code-user_withdraw_code', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_withdraw_code-user_withdraw_code',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_withdraw_code/user_withdraw_code',
  windowTop:0
}
},
{
path: '/pages/bundle/user_wallet/user_wallet',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的贝壳"})
      },
      [
        createElement('pages-bundle-user_wallet-user_wallet', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_wallet-user_wallet',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_wallet/user_wallet',
  windowTop:0
}
},
{
path: '/pages/bundle/user_integral/user_integral',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的积分"})
      },
      [
        createElement('pages-bundle-user_integral-user_integral', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_integral-user_integral',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_integral/user_integral',
  windowTop:0
}
},
{
path: '/pages/bundle/user_bill/user_bill',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"贝壳明细"})
      },
      [
        createElement('pages-bundle-user_bill-user_bill', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_bill-user_bill',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_bill/user_bill',
  windowTop:0
}
},
{
path: '/pages/bundle/user_payment/user_payment',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"用户充值"})
      },
      [
        createElement('pages-bundle-user_payment-user_payment', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_payment-user_payment',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_payment/user_payment',
  windowTop:0
}
},
{
path: '/pages/bundle/widthdraw_result/widthdraw_result',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"提现结果"})
      },
      [
        createElement('pages-bundle-widthdraw_result-widthdraw_result', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-widthdraw_result-widthdraw_result',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/widthdraw_result/widthdraw_result',
  windowTop:0
}
},
{
path: '/pages/bundle/server_explan/server_explan',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{})
      },
      [
        createElement('pages-bundle-server_explan-server_explan', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-server_explan-server_explan',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/server_explan/server_explan',
  windowTop:0
}
},
{
path: '/pages/bundle/message_center/message_center',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"消息中心"})
      },
      [
        createElement('pages-bundle-message_center-message_center', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-message_center-message_center',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/message_center/message_center',
  windowTop:0
}
},
{
path: '/pages/bundle/notice/notice',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"消息通知"})
      },
      [
        createElement('pages-bundle-notice-notice', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-notice-notice',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/notice/notice',
  windowTop:0
}
},
{
path: '/pages/bundle/user_profile/user_profile',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"个人设置"})
      },
      [
        createElement('pages-bundle-user_profile-user_profile', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-user_profile-user_profile',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/user_profile/user_profile',
  windowTop:0
}
},
{
path: '/pages/bundle/post_sale/post_sale',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"退款/售后"})
      },
      [
        createElement('pages-bundle-post_sale-post_sale', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-post_sale-post_sale',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/post_sale/post_sale',
  windowTop:0
}
},
{
path: '/pages/bundle/apply_refund/apply_refund',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"申请售后"})
      },
      [
        createElement('pages-bundle-apply_refund-apply_refund', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-apply_refund-apply_refund',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/apply_refund/apply_refund',
  windowTop:0
}
},
{
path: '/pages/bundle/after_sales_detail/after_sales_detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"售后详情"})
      },
      [
        createElement('pages-bundle-after_sales_detail-after_sales_detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-after_sales_detail-after_sales_detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/after_sales_detail/after_sales_detail',
  windowTop:0
}
},
{
path: '/pages/bundle/input_express_info/input_express_info',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"填写快递单号"})
      },
      [
        createElement('pages-bundle-input_express_info-input_express_info', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-input_express_info-input_express_info',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/input_express_info/input_express_info',
  windowTop:0
}
},
{
path: '/pages/bundle/contact_offical/contact_offical',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"联系客服"})
      },
      [
        createElement('pages-bundle-contact_offical-contact_offical', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-contact_offical-contact_offical',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/contact_offical/contact_offical',
  windowTop:0
}
},
{
path: '/pages/bundle/luckly_wheel/luckly_wheel',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"幸运抽奖"})
      },
      [
        createElement('pages-bundle-luckly_wheel-luckly_wheel', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-luckly_wheel-luckly_wheel',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/luckly_wheel/luckly_wheel',
  windowTop:0
}
},
{
path: '/pages/bundle/prize_record/prize_record',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"抽奖记录"})
      },
      [
        createElement('pages-bundle-prize_record-prize_record', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-prize_record-prize_record',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/prize_record/prize_record',
  windowTop:0
}
},
{
path: '/pages/bundle/bargain/bargain',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"砍价活动"})
      },
      [
        createElement('pages-bundle-bargain-bargain', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-bargain-bargain',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/bargain/bargain',
  windowTop:0
}
},
{
path: '/pages/bundle/bargain_process/bargain_process',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"砍价进度"})
      },
      [
        createElement('pages-bundle-bargain_process-bargain_process', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-bargain_process-bargain_process',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/bargain_process/bargain_process',
  windowTop:0
}
},
{
path: '/pages/bundle/bargain_code/bargain_code',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"砍价记录"})
      },
      [
        createElement('pages-bundle-bargain_code-bargain_code', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-bargain_code-bargain_code',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/bargain_code/bargain_code',
  windowTop:0
}
},
{
path: '/pages/bundle/recharge_code/recharge_code',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"充值记录","enablePullDownRefresh":false})
      },
      [
        createElement('pages-bundle-recharge_code-recharge_code', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-recharge_code-recharge_code',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/recharge_code/recharge_code',
  windowTop:0
}
},
{
path: '/pages/bundle/balance_transfer/balance_transfer',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"贝壳转账"})
      },
      [
        createElement('pages-bundle-balance_transfer-balance_transfer', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-balance_transfer-balance_transfer',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/balance_transfer/balance_transfer',
  windowTop:0
}
},
{
path: '/pages/bundle/integral_transfer/integral_transfer',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"积分转账"})
      },
      [
        createElement('pages-bundle-integral_transfer-integral_transfer', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-integral_transfer-integral_transfer',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/integral_transfer/integral_transfer',
  windowTop:0
}
},
{
path: '/pages/bundle/transfer_record/transfer_record',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"转账记录"})
      },
      [
        createElement('pages-bundle-transfer_record-transfer_record', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-transfer_record-transfer_record',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/transfer_record/transfer_record',
  windowTop:0
}
},
{
path: '/pages/bundle/integral_transfer_record/integral_transfer_record',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"转出记录"})
      },
      [
        createElement('pages-bundle-integral_transfer_record-integral_transfer_record', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-integral_transfer_record-integral_transfer_record',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/integral_transfer_record/integral_transfer_record',
  windowTop:0
}
},
{
path: '/pages/bundle/set_pay_pwd/set_pay_pwd',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"设置支付密码"})
      },
      [
        createElement('pages-bundle-set_pay_pwd-set_pay_pwd', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-set_pay_pwd-set_pay_pwd',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/set_pay_pwd/set_pay_pwd',
  windowTop:0
}
},
{
path: '/pages/bundle/forget_pay_pwd/forget_pay_pwd',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"忘记支付密码"})
      },
      [
        createElement('pages-bundle-forget_pay_pwd-forget_pay_pwd', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-bundle-forget_pay_pwd-forget_pay_pwd',
  isNVue:false,maxWidth:0,
  pagePath:'pages/bundle/forget_pay_pwd/forget_pay_pwd',
  windowTop:0
}
},
{
path: '/choose-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-choose-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'choose-location',
  pagePath:'/choose-location'
}
}
    ,
{
path: '/open-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-open-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'open-location',
  pagePath:'/open-location'
}
}
    ]
global.UniApp && new global.UniApp();
